<div class="bannerPosition">
    <div class="bannerItens">
        <owl-carousel-o [options]="customOptionsBanner">
            <ng-container *ngFor="let slide of slidesInfoKW">
                <ng-template class carouselSlide [id]="slide.id">
                    <img [src]="slide.src">
                    <h1>{{slide.title}}</h1>
                    <div class="bannerDescription">{{slide.description}}</div>
                    <div class="bannerApps" *ngIf="slide.app1">
                        <div class="LIs">
                            <li>{{slide.app1}}</li>
                            <li>{{slide.app2}}</li>
                            <li>{{slide.app3}}</li>
                            <li>{{slide.app4}}</li>
                            <li>{{slide.app5}}</li>
                        </div>
                        <div class="LIs">
                            <li>{{slide.app6}}</li>
                            <li>{{slide.app7}}</li>
                            <li>{{slide.app8}}</li>
                            <li>{{slide.app9}}</li>
                            <li>{{slide.app10}}</li>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>


<div class="trabalhosPosition">
    <h1 class="titulos">
        Trabalhos Realizados
    </h1>
    <div class="trabalhosGrid">
        <div class="trabalhosItens">
            <h1>Elipse Power</h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesElipsePower">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="trabalhosItens">
            <h1>Elipse E3</h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesElipseE3">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="trabalhosItens">
            <h1>SAGE</h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesSAGE">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="trabalhosItens">
            <h1>iFix                         </h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesIfix">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="trabalhosItens">
            <h1>RTAC</h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesRTAC">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="trabalhosItens">
            <h1>EcoSUI e C264</h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesEcoSUI">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="trabalhosItens">
            <h1>Indusoft</h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesIndusoft">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="trabalhosItens">
            <h1>Treinamentos</h1>
            <owl-carousel-o [options]="customOptionsTrabalhos">
                <ng-container *ngFor="let slide of slidesTreinamentos">
                    <ng-template class carouselSlide [id]="slide.id">
                        <div>{{slide.title}}</div>
                        <img [src]="slide.src">
                        <div class="trabDescription">{{slide.description}}</div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>



<div class="clientesPosition">
    <h1 class="titulos">
        Nossos Clientes
    </h1>
    <div class="clientesGrid">
        <div class="clientesItens">
            <a href="https://www.weg.net/institutional/BR/pt/" target="_blank">
                <img src="/clientes/weg.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.siemens.com/br/pt.html" target="_blank">
                <img src="/clientes/siemens.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.arteche.com/pt" target="_blank">
                <img class="menorIMG" src="/clientes/arteche.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.cgteletrosul.com.br" target="_blank">
                <img src="/clientes/eletrosul.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://dmasistemas.com" target="_blank">
                <img class="menorIMG" src="/clientes/dma.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.edp.com.br" target="_blank">
                <img src="/clientes/edp.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.eletronorte.com.br" target="_blank">
                <img src="/clientes/eletronorte.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://echoenergia.com.br" target="_blank">
                <img class="menorIMG" src="/clientes/echoenergia.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.cpfl.com.br" target="_blank">
                <img src="/clientes/cpfl.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.cemig.com.br" target="_blank">
                <img class="menorIMG" src="/clientes/cemig.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://www.chesf.com.br/Pages/default.aspx" target="_blank">
                <img src="/clientes/chesf.png">
            </a>
        </div>
        <div class="clientesItens">
            <a href="https://selinc.com/pt/" target="_blank">
                <img class="menorIMG" src="/clientes/SEL.png">
            </a>
        </div>
    </div>
</div>