import { Component } from '@angular/core';

@Component({
  selector: 'app-topbar',
  standalone: true,
  templateUrl: './app.topbar.component.html',
  styleUrl: './app.topbar.component.css'
})
export class AppTopbarComponent {

}
