<div class="contatoPosition">
    <div class="contato">
        <img class="contatoLogo" src="/KWaut.png">
        <div class="redesSociais">
            <a href="https://www.facebook.com/KWaut.CTBA?locale=pt_BR" target="_blank">
                <img src="/redes_sociais/facebook_logo.png">
            </a>
            <a href="https://www.instagram.com/kwaut_com/" target="_blank">
                <img src="/redes_sociais/instagram_logo.png">
            </a>
            <a href="https://www.linkedin.com/company/kwaut/" target="_blank">
                <img src="/redes_sociais/linkedin_logo.png">
            </a>
        </div>
    </div>
    <div class="contato">
        <div class="contatoLines">
            <img src="/contato/localizacao.png">
            <div>
                <p>Rua Professora Maria de Assumpção, 498</p>
                <p>Casa 12 - Hauer - Curitiba/PR</p>
            </div>
        </div>
        <div class="contatoLines">
            <img src="/contato/telefone.png">
            <p>+55 (41) 3276-1645</p>
        </div>
        <div class="contatoLines">
            <img src="/contato/email.png">
            <p>contato&#64;kwaut.com</p>
        </div>
    </div>
</div>
<div class="footerPosition">
    <div class="LTDA">
        <p>© 2024 KWaut Sistemas de Automação Ltda.</p>
    </div>
</div>